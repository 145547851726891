<template>
  <header v-if="info" class="congainer">
    <div v-if="info.image" class="header-cover">
      <swiper v-if="isImageList" :pagination="{ clickable: true }" autoplay>
        <swiper-slide
          v-for="(image, index) in info.image"
          :key="'image' + index"
        >
          <img :src="image" />
        </swiper-slide>
      </swiper>
      <img v-else :src="info.image" />
    </div>
    <div class="header-desc">
      <button v-if="menu" class="menu" @click="showMenu">
        <div class="icon">
          <img src="@/assets/img/icon/menu_brown.svg" />
        </div>
        菜單
      </button>
      <div class="icon"><img :src="info.icon" /></div>
      <h1 :class="{ 'text-left': alignType == 3 }">
        {{ info.title }}
      </h1>
      <div class="title-border"></div>
      <h2>{{ info.subTitle }}</h2>
      <div class="detail">
        {{ info.profile }}
      </div>
      <div
        v-if="actions.length && (actions.length > 1 || url)"
        class="action-button"
      >
        <button
          v-for="(action, index) in actions"
          :key="'action' + index"
          @click="action.action"
        >
          {{ action.actionName }}
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import SwiperCore, { Pagination, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
SwiperCore.use([Pagination, Autoplay])
export default {
  name: 'Header',
  components: { Swiper, SwiperSlide },
  props: {
    info: {
      type: Object,
      default() {
        return {
          image: [],
          icon: '',
          title: 'title',
          subTitle: '熱情款待來自世界各地的旅客，精彩演繹全新風貌',
          profile:
            '中文假字約70字...人我念總海我要中頭：頭無候詩影著告血來以臺趣復正完使，術前轉作身一力思們人裡速色民價出回遊臺比雖此命正職。們情沒來們許成，體死能和場蘭人。利民上上力的漸高，智用館是，向成實分別到。',
        }
      },
    },
    actions: {
      type: Array,
      default() {
        return []
      },
    },
    menu: {
      type: Boolean,
      default: false,
    },
    alignType: {
      type: Number,
      default: 1,
    },
    url: {
      type: String,
      default: '',
    },
  },
  methods: {
    showMenu() {
      this.$emit('showMenu')
    },
  },
  computed: {
    isImageList() {
      return this.info.image.constructor == Array
    },
  },
}
</script>

<style scoped lang="scss">
.header-desc {
  position: relative;
  padding: 0 5.33%;
  @apply pt-4  pb-8;
  .icon {
    margin-bottom: 16px;
    img {
      margin: auto;
    }
  }
}
.header-cover {
  img {
    width: 100%;
  }
  ::v-deep(.swiper-pagination) {
    bottom: 3px;
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      opacity: 1;
      margin: 0 8px;
      background-color: #a09b91;
      &-active {
        background-color: #fefdfb;
      }
    }
  }
}
h1 {
  position: relative;
  word-break: break-all;
}
.menu {
  font-family: none;
  color: #9d8147;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 22px;
  position: absolute;
  z-index: 5;
  right: 5.33%;
  top: -20px;
  background: #ffffff;
  border: none;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.03);
  border-radius: 100px;
  .icon {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }
}
.title-border {
  width: 8.4%;
  border-top: 2px solid #ceb17d;
  margin: 15px auto 0;
  @apply pb-4;
}
.text-left + .title-border {
  margin-left: 0;
}
.detail {
  @apply font-light pt-2;
  font-size: 16px;
  line-height: 26px;
  color: #888;
  font-family: PingFang TC, sans-serif;
}
.action-button {
  @apply pt-6 flex space-x-2;
}
</style>
